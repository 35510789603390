<template>
<div class="container">

  <div class="row">
  <b-table id="my-table"  :per-page="perPage"
      :current-page="currentPage" :head-variant="'dark'"  :outlined="true" border hover :items="items" :fields="fields">
  <template v-slot:cell(actions)="data">
      <button @click="editRow(data.value.age)"  class="btn btn-sm btn-primary" >Edit</button> <button @click="deleteRow(data.value.age)" class="btn btn-sm btn-primary">Delete</button>
    </template>
  </b-table>
  <div class="overflow-auto float-right">
      <b-pagination   :per-page="perPage"
      aria-controls="my-table" v-model="currentPage" :total-rows="rows"></b-pagination>
    </div>
</div>
</div>

</template>

<script>
export default {
   data() {
    return {
      currentPage:1,
      perPage:5,
      name: 'BootstrapVue',
      show: true,
     items: [
       {
            requesttype: "Product",
            mediacode: "M",
            requestor: "Edinburgh",
            clientname: "Chandru",
            sttusprogress: "Annalect",
            dateofrequest: "01/04/2019",
            requestedval: "ABC",
            completedval: "ABC"
          }
        ], fields: [
          {
            key: 'requesttype',
            label: 'Request Type',
            sortable: true,
            filter:true
          },
          {
            key: 'mediacode',
            label: 'Media Code',
            sortable: true
          },
          {
            key: 'requestor',
            label: 'Requestor',
            sortable: true,             
          },
          {
            key: 'clientname',
            label: 'Client Name',
            sortable: true,             
          },
          {
            key: 'sttusprogress',
            label: 'Status/Progress',
            sortable: true,             
          },
          {
            key: 'dateofrequest',
            label: 'Date of Request',
            sortable: true,             
          },
          {
            key: 'requestedval',
            label: 'Requested Value',
            sortable: true,             
          },
          {
            key: 'completedval',
            label: 'Completed Value',
            sortable: true,             
          }

          ,'actions'
        ],
    }
  },
  watch: {
    show() {
    }
  }, computed: {
      rows() {
        return this.items.length
      }
    },
  methods: {
    toggle() {
      this.show = !this.show
    },
    dismissed() {
    },editRow(){
      
    },deleteRow(){
      
    }
  }
}
</script>